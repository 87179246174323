<template>
  <div class="sideBar">
    <div class="side-item-block">
      <div v-for="sub in sidebarItem" :class="defaultActive==sub.parentName?'whitefont-btn index':'orange-btn index'"
           @click="handleMenuItem(sub)">
        <span>{{sub.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  let vm
  export default {
    name: 'SideBar',
    data() {
      return {
        sidebarItem: [
          {
            title: '商品列表',
            shortName: 'list',
            parentName: 'commodity',
            icon: 'el-icon-document',
            name: 'List',
          },
          {
            title: '兑换记录',
            shortName: 'order',
            parentName: 'exchange',
            icon: 'el-icon-menu',
            name: 'Order',
          }
        ],
        defaultActive: this.$route.meta.parentName
      }
    },
    created() {
      let panduan = JSON.parse(localStorage.getItem('userinfo'))
      if (!panduan) {
      }
    },
    computed: {
      sidebar() {
        return this.$store.state.user.sidebarItem
      },

      active() {
        return this.$route.meta.parentName
      }
    },
    watch: {
      sidebar(val, old) {
        // vm.sidebarItem = val;
        vm.$store.commit('user/SET_SIDEBAR', vm.sidebarItem)
        vm.$forceUpdate()
      },

      active(val, old) {
        vm.defaultActive = val
      }
    },
    mounted() {
      vm = this
      // vm.init();
      this.handleMenuItem(this.sidebarItem[0])
      console.log(this.defaultActive)
    },
    methods: {
      handleOpen(key, keyPath) {},
      handleClose(key, keyPath) {},

      handleMenuItem(obj) {
        vm.$router.push(obj.shortName)
        vm.$store.commit('tagsView/pushtags', obj)
      },

      init() {
        if (localStorage.getItem('village_sidebar')) {
          vm.sidebarItem = JSON.parse(localStorage.getItem('village_sidebar'))
        }
        vm.$store.commit('user/SET_SIDEBAR', vm.sidebarItem)
      },

      toHome() {
        vm.$router.push({ name: 'Home' })
        let obj = {
          name: 'Home',
          shortName: 'Home',
          title: '首页'
        }
        vm.$store.commit('tagsView/pushtags', obj)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../../styles/sidebar';

  /deep/.el-menu {
    padding-left: 18px !important;
  }
</style>
