/**
 * 基础路由
 * @type { *[] }
 */
import Layout from '@/layout'

export const constantRouterMap = [

  {
    path:'/login',
    name:'Login',
    component: () => import('@/views/Login'),
  },
  {
    path:'/photo',
    name:'Photo',
    component: () => import('@/views/photo'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '首页',
      keepAlive: false
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/commodity/list'),
        meta: { title: '首页', keepAlive: false,shortName:'Home' }
      },
    ]
  },
  {
    path: '/mechanism',
    component: Layout,
    meta:{title:'机构创建',parentName:'CommunityManage'},
    children: [
      {
        path: '/establish',
        name: 'Establish',
        component: () => import('@/views/mechanism/establish'),
        meta: { title: '创建机构', keepAlive: false,shortName:'CJJGJCXX',parentName:'Mechanism' }
      },
      {
        path: '/perfect',
        name: 'Perfect',
        component: () => import('@/views/mechanism/perfect'),
        meta: { title: '完善机构', keepAlive: false,shortName:'WSJGJCXX',parentName:'Mechanism' }
      },
      {
        path: '/change',
        name: 'Change',
        component: () => import('@/views/mechanism/change'),
        meta: { title: '商家信息', keepAlive: false,shortName:'CHANGE',parentName:'Mechanism' }
      },
    ]
  },

  {
    path: '/commodity',
    component: Layout,
    meta: { title: '商品管理',parentName:'commodityManage'},
    children: [
      {
        path: '/list',
        name: 'List',
        component: () => import('@/views/commodity/list'),
        meta: { title: '商品列表', keepAlive:false, shortName:'SPLB',parentName:'commodity' },
      },
      {
        path: '/addProductss',
        name: 'addProductss',
        component: () => import('@/views/commodity/addProductss'),
        meta: { title: '添加商品', keepAlive:false, shortName:'TJSP',parentName:'commodity' },
      },
      {
        path: '/showProductss',
        name: 'showProductss',
        component: () => import('@/views/commodity/showProductss'),
        meta: { title: '查看商品', keepAlive:false, shortName:'TJSP',parentName:'commodity' },
      }
    ]
  },


  {
    path: '/exchange',
    component: Layout,
    meta: { title: '兑换管理',parentName:'ResidentsManage'},
    children: [
      {
        path: '/order',
        name: 'Order',
        component: () => import('@/views/exchange/order'),
        meta: { title: '兑换订单管理', keepAlive:false, shortName:'DHDDGL',parentName:'exchange', },
      },

    ]
  },
];
