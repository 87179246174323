import api from './index'
// axios
import request from '@/utils/request'
import { baseApiApplet } from '@/config'

export function login(data) {
  return request({
    url:api.login,
    method: 'post',
    data
  })
}

export function topAdd(data) {
  return request({
    url:api.topAdd,
    method: 'post',
    data
  })
}

export function topDel(data, id) {
  return request({
    url:api.topDel + `/${id}`,
    method: 'delete',
    data
  })
}

export function getInfo(params) {
  return request({
    url:api.userinfo,
    method: 'get',
    params
  })
}

export function relation(params, id) {
  return request({
    url:api.relation + `${id}`,
    method: 'get',
    params
  })
}

export function top(params) {
  return request({
    url:api.top,
    method: 'get',
    params
  })
}

export function topShow(params, id) {
  return request({
    url:api.topShow + `${id}`,
    method: 'get',
    params
  })
}

export function user(params) {
  return request({
    url:api.user,
    method: 'get',
    params
  })
}


export function getMember(params) {
  return request({
    url:api.member,
    method: 'get',
    params
  })
}

export function getDetail(params) {
  return request({
    url:api.detail,
    method: 'get',
    params
  })
}

export function setApply(data) {
  return request({
    url:api.apply,
    method: 'post',
    data
  })
}

export function addUser(data) {
  return request({
    url:api.member,
    method: 'post',
    data
  })
}

export function Obinding(data) {
  return request({
    url:api.Obinding,
    method: 'post',
    data
  })
}

export function bindType(data) {
  return request({
    url:api.bind,
    method: 'post',
    data
  })
}







//社区
export function sq_list(params) {
  return request({
    url:api.sq_list,
    method: 'get',
    params
  })
}

export function add_area(data) {
  return request({
    url:api.sq_list,
    method: 'post',
    data
  })
}

export function del_area(data) {
  return request({
    url:api.del_area,
    method: 'post',
    data
  })
}

export function edit_area(data) {
  return request({
    url:api.edit_area,
    method: 'post',
    data
  })
}


//节点管理
export function getNode(params) {
  return request({
    url:api.node,
    method: 'get',
    params
  })
}

export function del_node(data) {
  return request({
    url:api.del_node,
    method: 'post',
    data
  })
}

export function update_node(data) {
  return request({
    url:api.update_node,
    method: 'post',
    data
  })
}

export function add_node(data) {
  return request({
    url:api.node,
    method: 'post',
    data
  })
}


//党支部
export function dzz_list(params) {
  return request({
    url:api.dzz_list,
    method: 'get',
    params
  })
}

export function add_banch(data) {
  return request({
    url:api.dzz_list,
    method: 'post',
    data
  })
}

export function del_banch(data) {
  return request({
    url:api.del_branch,
    method: 'post',
    data
  })
}

export function update_banch(data) {
  return request({
    url:api.update_branch,
    method: 'post',
    data
  })
}

//广告
export function ad_list(params) {
  return request({
    url:api.ad_list,
    method: 'get',
    params
  })
}

export function add_AD(data) {
  return request({
    url:api.ad_list,
    method: 'POST',
    data
  })
}

export function upload(data) {
  return request({
    url:api.upload,
    method: 'post',
    timeout:0,
    data
  })
}


export function del_AD(data) {
  return request({
    url:api.del_ad,
    method: 'POST',
    data
  })
}


export function update_AD(data) {
  return request({
    url:api.update_ad,
    method: 'POST',
    data
  })
}

//任务
export function get_task(params) {
  return request({
    url:api.task,
    method: 'get',
    params
  })
}

export function task_show(params) {
  return request({
    url:api.task_show,
    method: 'get',
    params
  })
}

export function join(params) {
  return request({
    url:api.join,
    method: 'get',
    params
  })
}

export function sign(data) {
  return request({
    url:api.sign,
    method: 'post',
    data
  })
}

export function cancel_task(data) {
  return request({
    url:api.cancel_task,
    method: 'post',
    data
  })
}

export function set_task(data) {
  return request({
    url:api.task,
    method: 'post',
    data
  })
}

export function del_task(data) {
  return request({
    url:api.del_task,
    method: 'post',
    data
  })
}

export function update_task(data) {
  return request({
    url:api.update_task,
    method: 'post',
    data
  })
}

export function task_confirm(data) {
  return request({
    url:api.task_confirm,
    method: 'post',
    data
  })
}



//新建内容
export function content(params) {
  return request({
    url:api.content,
    method: 'get',
    params
  })
}

export function add_content(data) {
  return request({
    url:api.content,
    method: 'post',
    data
  })
}

export function del_content(data) {
  return request({
    url:api.del_content,
    method: 'post',
    data
  })
}

export function update_content(data) {
  return request({
    url:api.update_content,
    method: 'post',
    data
  })
}

//积分
export function stream(params) {
  return request({
    url:api.stream,
    method: 'get',
    params
  })
}

export function set_stream(data) {
  return request({
    url:api.stream,
    method: 'post',
    data
  })
}


//账号管理
export function account(params) {
  return request({
    url:api.account,
    method: 'get',
    params
  })
}

export function add_account(data) {
  return request({
    url:api.account,
    method: 'post',
    data
  })
}

export function del_account(data) {
  return request({
    url:api.del_account,
    method: 'post',
    data
  })
}

export function update_account(data) {
  return request({
    url:api.update_account,
    method: 'post',
    data
  })
}

//角色
export function role(params) {
  return request({
    url:api.role,
    method: 'get',
    params
  })
}

export function add_role(data) {
  return request({
    url:api.role,
    method: 'post',
    data
  })
}

export function del_role(data) {
  return request({
    url:api.del_role,
    method: 'post',
    data
  })
}

export function update_role(data) {
  return request({
    url:api.update_role,
    method: 'post',
    data
  })
}

//忘记密码
export function forget_password(data){
  return request({
    url:api.forget_password,
    method: 'post',
    data
  })
}

//机构

export function organ(params){
  return request({
    url:api.organ,
    method: 'get',
    params
  })
}

export function organ_show_new(params, id){
  return request({
    url:api.organ + `/${id}`,
    method: 'get',
    params
  })
}
export function Ubinding(data){
  return request({
    url:api.Ubinding,
    method: 'post',
    data
  })
}

export function add_organ(data){
  return request({
    url:api.organ,
    method: 'post',
    data
  })
}
export function update_organ(data){
  return request({
    url:api.update_organ,
    method: 'post',
    data
  })
}

export function del_organ(data){
  return request({
    url:api.del_organ,
    method: 'post',
    data
  })
}

export function code(data){
  return request({
    baseURL: baseApiApplet,
    url:api.code,
    method: 'post',
    data
  })
}

export function organ_show(params){
  return request({
    url:api.organ_show,
    method: 'get',
    params
  })
}

export function organ_user(params){
  return request({
    url:api.organuser,
    method: 'get',
    params
  })
}

export function organ_user_show(params){
  return request({
    url:api.organuser_show,
    method: 'get',
    params
  })
}

export function del_organ_user(data){
  return request({
    url:api.del_organuser,
    method: 'post',
    data
  })
}

export function bind_organ(data){
  return request({
    url:api.bind_organ,
    method: 'post',
    data
  })
}



//党员
export function party(params){
  return request({
    url:api.party,
    method: 'get',
    params
  })
}

export function party_show(params){
  return request({
    url:api.party_show,
    method: 'get',
    params
  })
}

export function del_party(data){
  return request({
    url:api.del_party,
    method: 'post',
    data
  })
}

export function update_party(data){
  return request({
    url:api.update_party,
    method: 'post',
    data
  })
}

export function import_party(data){
  return request({
    url:api.import_party,
    method: 'post',
    data
  })
}

//修改密码
export function material(data){
  return request({
    url:api.material,
    method: 'post',
    data
  })
}


//节点权限
export function menu(params){
  return request({
    url:api.menu,
    method: 'get',
    params
  })
}

export function permission(params){
  return request({
    url:api.permission,
    method: 'get',
    params
  })
}

export function set_permission(data){
  return request({
    url:api.permission,
    method: 'post',
    data
  })
}

export function organ_list(data){
  return request({
    url:api.organ_list,
    method: 'post',
    data
  })
}


export function villages(params){
  return request({
    url:api.villages,
    method: 'get',
    params
  })
}

export function products(params) {
  return request({
    url:api.products,
    method: 'get',
    params
  })
}

export function getBinding(params) {
  return request({
    url:api.getBinding,
    method: 'get',
    params
  })
}

export function addProducts(data){
  return request({
    url:api.addProducts,
    method: 'post',
    data
  })
}
export function showProducts(data, id) {
  return request({
    url:api.showProducts + `/${id}`,
    method: 'get',
    data
  })
}

export function purchaseSure(data){
  return request({
    url:api.purchaseSure,
    method: 'post',
    data
  })
}

export function delProducts(data){
  return request({
    url:api.delProducts,
    method: 'delete',
    data
  })
}

export function status(data) {
  return request({
    url:api.status,
    method: 'put',
    data
  })
}

export function purchaseOrders(params) {
  return request({
    url:api.purchaseOrders,
    method: 'get',
    params
  })
}

export function orders(params) {
  return request({
    url:api.orders,
    method: 'get',
    params
  })
}
