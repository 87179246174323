<template>
  <div class="breadcrumb-block">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(m, index) in matchedItem"
        :key="index"
        :to="m.name ? { name: m.name, query: m.queryData } : ''"
        :class="m.name === curr_route ? 'route-active' : ''"
      >
        {{ m.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
let vm
export default {
  name: 'Breadcrumb',
  data() {
    return {
      matchedItem: [],
      curr_route: '',
      sidebarItem: []
    }
  },
  props: {
    queryData: {
      type: Object,
      default: () => {}
    },
    routeIdx: {
      type: Number
    }
  },
  created() {
    // console.log("这是")
    // console.log(this.matchedItem)
  },
  mounted() {
    vm = this
    vm.getSidebarItem()
  },
  methods: {
    getSidebarItem() {
      vm.matchedItem = vm.$route.matched
      vm.curr_route = vm.$route.name
      vm.sidebarItem = vm.$store.state.user.sidebarItem
      // console.log(vm.$route)
      // console.log(1)
      // console.log(vm.$store)

      let First = []
      let Second = []
      let Third = []
      let Four = []
      vm.sidebarItem.map(res => {
        res.children.map(sec => {
          if (sec.name === vm.curr_route) {
            First.push(res)
            Second.push(sec)
          } else {
            if (sec.children) {
              sec.children.map(third => {
                if (third.name === vm.curr_route) {
                  First.push(res)
                  Second.push(sec)
                  Third.push(third)
                } else {
                  if (third.children) {
                    third.children.map(four => {
                      if (four.name === vm.curr_route) {
                        First.push(res)
                        Second.push(sec)
                        Third.push(third)
                        Four.push(four)
                      }
                    })
                  }
                }
              })
            }
          }
        })
      })
      vm.matchedItem = First.concat(Second)
        .concat(Third)
        .concat(Four)
      let index = vm.routeIdx ? vm.routeIdx : vm.matchedItem.length - 2
      vm.matchedItem[index].queryData = vm.queryData
      console.log(vm.matchedItem)
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/variables';
.breadcrumb-block {
  padding: 28px 42px;
  border-bottom: 1px solid $line;
  .el-breadcrumb__item {
    position: relative;
    color: $gray32;
  }
  /deep/.el-breadcrumb__inner {
    color: #7A818A;
    font-weight: inherit;
  }
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #333E4C;
    font-weight: 600;
  }
  .route-active {
    position: relative;
    &:after {
      content: '';
      width: 56px;
      height: 4px;
      background: #333E4C;
      font-family: '.PingFang SC';
      border-radius: 0;
      display: block;
      position: absolute;
      bottom: -28px;
    }
  }
}
</style>
