<template>
  <div class="navbar">
    <div class="left">
      <div class="logo">
        <img src="../../assets/images/logo.png" alt="" />
      </div>
      <el-divider direction="vertical"></el-divider>
      <span class="f16 blackfont name">
        {{ '异洲共创家 - - ' + sheqv_address }}
      </span>
    </div>

    <div class="tool">
<!--      <el-input size="mini" placeholder="查询用户、活动、资产"></el-input>-->
<!--      <i class="el-icon-message"></i>-->
      <span v-if="!login" style="width: 157px">
        <span class="mr16 ver-middle"><router-link :to="{ path: '/login' }">登录</router-link></span>
      </span>
      <el-popover placement="bottom" v-if="login" width="200" trigger="hover">
        <span v-if="login" class="curpor" style="display: inline-block " slot="reference">
          <span class="f14 grayfont-65 " style="vertical-align: middle">{{ userInfo.realname }}</span>
          <el-avatar size="small" :src="this.ruleForm.logo || circleUrl" />
          <i class="el-icon-arrow-down"></i>
        </span>
        <div class="popover-block">
          <div>
            <div>{{ userInfo.realname }}</div>
            <div class="grayfont-32 mt10">账号：{{ userInfo.username }}</div>
          </div>
          <ul class="ul">
            <li class="li" @click="goEstablish()"><i class="iconfont icon-shezhi"></i><span>商家信息</span></li>
            <li class="li" @click="goPerfect()"><i class="iconfont icon-shezhi"></i><span>完善机构</span></li>
            <li class="li" @click="goPassword()"><i class="iconfont icon-mimasuo" /><span>修改密码</span></li>
            <li class="li" @click="loginOut()"><i class="iconfont icon-tuichu" /><span>退出登录</span></li>
          </ul>
        </div>
      </el-popover>
    </div>

    <el-dialog :close-on-click-modal="false" width="30%" title="修改密码" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :append-to-body="true">
      <el-form ref="formpwd" :rules="formRules" :model="form" label-position="top" label-width="60px" :hide-required-asterisk="true">
        <el-form-item label="原密码：" prop="oldPassword">
          <el-input type="password" v-model="form.old_password" placeholder="输入原密码"/>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPassword">
          <el-input type="password"  v-model="form.password" placeholder="输入新密码"/>
        </el-form-item>
        <el-form-item label="重复新密码：" prop="rePassword">
          <el-input type="password"  v-model="form.repassword" placeholder="重复新密码"/>
        </el-form-item>
        <el-form-item>
          <div  style="text-align: center;margin-top: 30px">
            <el-button @click="cancel()">取 消</el-button>
            <el-button type="warning" :disabled="loading" :loading="loading" @click="submitForm()">确定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getToken, removeToken } from '../../utils/auth'
import { material, organ_show_new } from '@/api/user'

export default {
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error('密码长度不能少于5位'))
      } else if (!this.regPwd.test(value)) {
        callback(new Error('密码只能包含数字或字母'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      circleUrl: require('../../assets/images/default-avatar.png'),
      userInfo: {},
      regPwd: /^[A-Za-z0-9]+$/,
      sheqv_address: '商家中心',
      login: false,
      dialogFormVisible: false,
      formRules:{
        old_password:[{required:true,message:'输入原密码',triggle:'blur'}],
        password:[{required:true,message:'输入新密码',triggle:'blur'},
          {validator: validatePassword, trigger: 'blur' }
        ],
        repassword:[
          {required:true,message:'再次输入新密码',triggle:'blur'},
          {validator: validatePass2, trigger: 'blur' }
        ],
      },
      form:{
        old_password:'',
        password:'',
        repassword:'',
      },
      formLabelWidth: '120px',
      ruleForm: {}
    }
  },
  computed: {
    monitor() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    monitor() {
      this.userInfo = this.$store.state.user.userInfo
      this.$forceUpdate()
    }
  },
  mounted() {
    this.getUserInfo()
    if (!getToken()) {
      this.login = false
      this.$router.push({ name: 'Login' })
    } else {
      this.login = true
    }
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userinfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
      }
      organ_show_new({}, this.userInfo.organ_id).then(res => {
        this.ruleForm = res.data
      })
    },
    loginOut() {
      removeToken()
      this.$store.commit('user/DEL_USERINFO')
      this.$store.commit('user/DEL_SIDEBAR')
      this.$store.commit('tagsView/delete_tagsView')
      this.$router.push('/login')
    },
    submitForm(){
      this.$refs.formpwd.validate((valid)=>{
        if(valid){
          this.loading = true

          material(this.form).then(res => {
            this.$message({
              type:'success',
              message:'修改成功，请重新登录！',
            })
            removeToken();
            this.loading = false
            this.$store.commit('user/DEL_USERINFO')
            this.$store.commit('tagsView/delete_tagsView')
            this.$router.push('login')
          }).catch(()=>{
            this.loading = false
          })
        }
      })
    },
    cancel(){
      this.$refs.formpwd.resetFields;
      this.dialogFormVisible = false;
    },
    goEstablish() {
      console.log(this.userInfo.organ_id)
      this.$router.push({ name: 'Change', query:{organid: this.userInfo.organ_id} })
    },
    goPerfect() {
      console.log(this.userInfo.organ_id)
      this.$router.push({ name: 'Perfect', query:{organid: this.userInfo.organ_id} })
    },
    goPassword(){
      this.dialogFormVisible = true
      // this.getDetail(this.userInfo.organ_id)
      this.$refs.formpwd.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.navbar {
  width: 100%;
  height: 48px;
  background-color: #FFFFFF;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);

  .left {
    display: flex;
    align-items: center;
    padding-left: 18%;
    // justify-content: space-between;

    .logo {
      width: 110px;
      height: 42px;
      background-color: #ffffff;
      border-radius: 5px;
      /*border: 1px dashed #000000;*/
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 79px;
        height: 32px;
        object-fit: cover;
        // margin-right: 15px;
        vertical-align: middle;
      }
    }

    .name {
      font-weight: 300;
      line-height: 22px;
      color: #3E5368;
      opacity: 1;
      font-family: '.PingFang SC';
      font-size: 17px;
      border-left: 1px solid #ffffff;
      padding-left: 15px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .iconfont {
    color: #595959;
    font-size: 16px;
    margin-right: 26px;
    vertical-align: middle;
  }
  .avtar {
  }
}

.tool {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 160px;
    border-radius: 5px;
  }
}

.popover-block {
  ul {
    border-top: 1px solid $line;
    margin-top: 12px;
    padding: 10px 0 0;
    li {
      padding: 7px 5px;
      cursor: pointer;
      .iconfont {
        font-size: 14px;
        color: #acacac;
        margin-right: 12px;
      }
    }
  }
}

/deep/.el-avatar--small {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}
/deep/.el-popover {
  padding: 16px !important;
}
</style>
