// 正式
module.exports = {
  title: '共创家—商家端管理',
  baseUrl: 'https://applet.gongchuangjia.com/', // 正式项目地址
  baseApi: 'https://applet.gongchuangjia.com/admin', // 正式api请求地址
  baseApiApplet: 'https://applet.gongchuangjia.com/applet', //增加验证码发送地址
  APPID: 'wx489c93ebee8842f2',
  APPSECRET: '',
  $cdn: 'https://hyjy-oss.oss-cn-chengdu.aliyuncs.com/mp/assets/'
}
