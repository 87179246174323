<template>
  <div class="app-main">
<!--    <tags-view v-if="tagsView.length > 0"/>-->
    <div>
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" v-if="!$route.meta.keepAlive" />
      </transition>
    </div>
    <div>

      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
      </transition>

    </div>

  </div>
</template>

<script>
  import TagsView from './TagsView'
  export default {
    name: 'AppMain',
    components:{TagsView},
    data(){
      return{
        tagsView:[],
      }
    },
    computed: {
      key() {
        return this.$route.path
      },
      visitedViews() {
        return this.$store.state.tagsView.tags
      },
    },
    watch:{
      visitedViews(){
        this.tagsView = this.$store.state.tagsView.tags;
      },
      $route:{
        handler(val,oldval){
          let obj = {
            name: val.name,
            shortName: val.meta.shortName,
            title:  val.meta.title,
            parentName:val.name,
          }
          val.query?obj.query=val.query:"";
          this.$store.commit('tagsView/pushtags',obj);
          this.getTagsView();
        },
        // 深度观察监听
        deep: true
      }
    },
    mounted() {
      this.getTagsView();
    },
    methods:{
      getTagsView(){
        if(sessionStorage.getItem('tags')){
          this.tagsView = JSON.parse(sessionStorage.getItem('tags'));
          this.$store.state.tagsView.tags = JSON.parse(sessionStorage.getItem('tags'));
        }
      },
    }
  }
</script>

<style scoped lang="scss">
.app-main {
  /*50 = navbar  */
  /*width: calc(100% - 184px);*/
  /*position: relative;*/
  /*padding-left: 24px;*/
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  border-radius: 6px;
  &::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background-color: #e9e9e9;
  }
  &::-webkit-scrollbar-thumb {
    background: #D1D1D1;
    border-radius: 6px;
    width: 6px;
  }
}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
