// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import '@/styles/index.scss' // global css
import globalFun from './utils/globalFun'

// 设置 js中可以访问 $cdn
import { $cdn } from '@/config'
Vue.prototype.$cdn = $cdn

// 全局引入按需引入UI库 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Sentry.init({   //process.env.NODE_ENV === "production" &&
  dsn: 'http://7c88cde6b70040dd9c54ee0f762034c3@116.62.243.43:9000/9',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

Vue.config.productionTip = false;
Vue.prototype.globalFun = globalFun;

Vue.use(ElementUI);
Vue.use(VueQuillEditor);




new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
